
import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core';
import { required } from "@vuelidate/validators";

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';

export default defineComponent({
  components: {
    Dialog,
    Button,
    InputText,
    Calendar
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      newTab: {
        label: {
          required,
        },
        range: {
          required,
        },
      },
    }
  },

  data() {
    return {
      showAddTabDialog: false,
      submitted: false,
      newTab: {
        label: '',
        range: null,
      },
    }
  },

  emits: ['addTab'],

  methods: {
    showAddTab() {
      this.showAddTabDialog = true;
    },

    closeAddTab() {
      this.showAddTabDialog = false;
      this.newTab = {
        label: '',
        range: null,
      };
      this.submitted = false;
    },

    addTab() {
      this.submitted = true;

      if (this.v$.$invalid) {
        return;
      }
      if (!(this.newTab.range as any)[1] || (this.newTab.range as any)[1] === null) {
        (this.newTab.range as any)[1] = (this.newTab.range as any)[0]
      }
      this.$emit('addTab', this.newTab);
      this.newTab = {
        label: '',
        range: null,
      };
      this.showAddTabDialog = false;
      this.submitted = false;
    },
  }
})
