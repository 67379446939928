import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "col-12 pt-3 mt-1 px-0 xl:flex add-custom-ta-btn-container" }
const _hoisted_2 = { class: "grid p-0 m-0 px-2" }
const _hoisted_3 = { class: "p-inputgroup mt-2" }
const _hoisted_4 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '104px' }
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "p-error pl-2" }
const _hoisted_7 = { class: "p-inputgroup mt-2" }
const _hoisted_8 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '104px' }
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "p-error pl-2" }
const _hoisted_11 = { class: "flex justify-content-end pl-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        label: "Custom Date Range",
        class: "p-button-success mt-1 text-sm add-tab-button",
        style: { marginTop: '-6px !important' },
        onClick: _ctx.showAddTab
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showAddTabDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAddTabDialog) = $event)),
      modal: "",
      closable: false,
      header: "Add Custom Date Range Tab",
      class: "w-11 md:w-9 lg:w-6 xl:w-4",
      onHide: _ctx.closeAddTab
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Button, {
            label: "Cancel",
            class: "p-button-outlined",
            onClick: _ctx.closeAddTab
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "Add Tab",
            icon: "pi pi-check",
            onClick: _ctx.addTab
          }, null, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("small", _hoisted_4, "Tab Title " + _toDisplayString(_ctx.newTab.label ? '' : '*'), 1),
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: _ctx.newTab.label,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTab.label) = $event)),
              class: _normalizeClass(["text-sm", {'p-invalid': _ctx.submitted && _ctx.v$.newTab.label.required.$invalid}]),
              placeholder: "Enter Tab Title"
            }, null, 8, ["modelValue", "class"])
          ]),
          (_ctx.submitted && _ctx.v$.newTab.label.required.$invalid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.v$.newTab.label.required.$message), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("small", _hoisted_8, "Date Range " + _toDisplayString(_ctx.newTab.range ? '' : '*'), 1),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.newTab.range,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newTab.range) = $event)),
              format: "MM-dd-yyyy",
              selectionMode: "range",
              manualInput: false,
              placeholder: "Select Date Range",
              showButtonBar: true,
              showIcon: true,
              class: _normalizeClass(["range-calendar", {'p-invalid': _ctx.submitted && _ctx.v$.newTab.range.required.$invalid}])
            }, null, 8, ["modelValue", "class"])
          ]),
          (_ctx.submitted && _ctx.v$.newTab.range.required.$invalid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.v$.newTab.range.required.$message), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ], 64))
}