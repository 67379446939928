<template>
  <Card>
    <template #title> Payment </template>
    <template #content>
      <div class="grid">
        <div class="sm:col-12 md:col-12 lg:col-6">
          <Card>
            <template #content>
              <div v-if="payment.achAllowed">
                <div class="grid">
                  <div class="field sm:col-12 md:col-12 lg:col-12 p-0">
                    <ToggleButton
                      v-model="CC"
                      onLabel="Pay by Credit Card"
                      offLabel="Pay by Credit Card"
                      onIcon="pi pi-credit-card"
                      offIcon="pi pi-credit-card"
                      @click="() => { setCCPaymentFormDefaults() }"
                    />
                    &nbsp;
                    <ToggleButton
                      v-model="ACH"
                      onLabel="Pay by eCheck"
                      offLabel="Pay by eCheck"
                      onIcon="pi pi-wallet"
                      offIcon="pi pi-wallet"
                      @click="() => { setACHPaymentFormDefaults() }"
                    />
                  </div>
                </div>
                &nbsp;
              </div>
              <div v-if="this.ACH && payment.achAllowed">
                <span class="font-medium text-bluegray-700 mb-2">Select an Account</span>
                <!-- TODO: test -->
                <DataTable v-model:selection="selectedAccount" :value="ach_accounts" responsiveLayout="stack" breakpoint="1279px" class="p-datatable-sm lg:mt-2 bordered-table with-footer" @row-select="this.displayAddAccount = false; this.route = this.selectedAccount.bank_routing; this.account = this.selectedAccount.bank_account; this.type = this.selectedAccount.account_type">
                  <template #empty> No accounts on file. </template>
                  <Column selectionMode="single" headerStyle="width: 1rem"></Column>
                  <Column field="bank_token" header="Account #" style="width: 35%">
                  </Column>
                  <Column field="bank_routing" header="Routing #" style="width: 35%">
                  </Column>
                  <Column field="account_type" header="Type" style="min-width: 25%">
                  </Column>
                  <template #footer> <Button label="+ Use a New Account" severity="secondary" text @click="toggleUseNewAccount" class="h-2rem use-new-card text-md"/> </template>
                </DataTable>
                <div v-if="this.selectedAccount !== ''" class="field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start">
                  <Checkbox id="agreement" v-model="agreement" :binary="true" />
                  <label for="agreement"
                    >By submitting this payment, you acknowledge that you have authorized
                    {{ session.client }} to debit your account, and you accept responsibility for
                    any resulting fees.</label
                  >
                </div>
                <div v-if="this.displayAddAccount" class="col-12 grid mt-2">
                  <div class="field col-12 md:col-6 lg:col-12 xl:col-6 mb-2 pt-2" style="min-width: 250px;">
                    <span class="p-float-label">
                      <InputText
                        type="number"
                        id="route"
                        v-model="route"
                        :useGrouping="false"
                        @change="validatePaymentForm"
                        class="h-2rem"
                      />
                      <label for="route">Routing Number</label>
                      &nbsp;
                      <i
                        class="pi pi-info-circle"
                        v-tooltip.top="{
                          value:
                            '<img src=&quot;./images/check.png&quot; height=&quot;150&quot; width=&quot;278&quot;>',
                          escape: true,
                        }"
                      ></i>
                    </span>
                  </div>
                  <div class="field col-12 md:col-6 lg:col-12 xl:col-6 mb-0" style="min-width: 250px;">
                    <span class="p-float-label">
                      <InputText
                        type="number"
                        id="account"
                        v-model="account"
                        :useGrouping="false"
                        class="h-2rem"
                      />
                      <label for="account">Account Number</label>
                    </span>
                  </div>
                  <div class="field-radiobutton col-6 md:col-6 lg:col-6">
                    <RadioButton
                      id="checking"
                      name="type"
                      value="Checking"
                      v-model="type"
                    />
                    <label for="checking">Checking</label>
                  </div>
                  <div class="field-radiobutton col-6 md:col-6 lg:col-6">
                    <RadioButton
                      id="savings"
                      name="type"
                      value="Savings"
                      v-model="type"
                    />
                    <label for="savings">Savings</label>
                  </div>
                  <div
                    class="
                      field-checkbox
                      sm:col-12
                      md:col-12
                      lg:col-12
                      align-items-start
                    "
                  >
                    <Checkbox
                      id="agreement"
                      v-model="agreement"
                      :binary="true"
                    />
                    <label for="agreement"
                      >By submitting this payment, you acknowledge that you have
                      authorized {{ session.client }} to debit your account, and
                      you accept responsibility for any resulting fees.</label
                    >
                  </div>
                  <div  class="field-checkbox sm:col-12 md:col-12 lg:col-12 align-items-start">
                    <Checkbox id="saveAccount" v-model="saveAccount" :binary="true" />
                    <label for="saveAccount"
                      >Save this account for future use</label
                    >
                  </div>
                  <div v-if="this.allowWaiveConvenienceFee"  class="
                      field-checkbox
                      sm:col-12
                      md:col-12
                      lg:col-12
                      align-items-start
                    "><Checkbox
                      id="overrideACHConvenienceFee"
                      v-model="waiveACHConvenienceFeeValue"
                      :binary="true"
                      @change="onWaiveACHFeeChange"
                    />
                    <label for="overrideACHConvenienceFee"
                      >Waive Convenience Fee</label
                    >
                  </div>
                <div v-if="this.getACHFee > 0 && this.ACH && !this.waiveACHConvenienceFeeValue" class="justify-content-center">A fee of {{ this.getACHFee }}% will be added to this ACH Payment.</div>
                
                </div>
              </div>

              <div v-if="this.ACHFee  > 0 && this.ACH" class="justify-content-center">A fee of {{ this.ACHFee }}% will be added to your ACH Payment.</div>

              <div v-if="this.CC || !payment.achAllowed">
                <span class="font-medium text-bluegray-700 mb-2">Select a Credit Card</span>
                <DataTable v-model:selection="selectedCC" :value="this.ccList" responsiveLayout="stack" breakpoint="1279px" class="p-datatable-sm mt-0 lg:mt-2 bordered-table with-footer" @row-select="this.displayIframe = false; this.success=true;">
                  <template #empty> No credit cards on file. </template>
                  <Column selectionMode="single" headerStyle="width: 2rem"></Column>
                  <Column field="cardholder_name" header="Name" style="width: 40%"></Column>
                  <Column field="credit_card_no" header="Credit Card Number" style="width: 35%">
                    <template #body="slotProps">
                      {{ formatCardNumber(slotProps.data.credit_card_id) }}
                    </template>
                  </Column>
                  <Column field="credit_card_exp" header="Expiry Date" style="width: 4rem">
                    <template #body="slotProps">
                      {{ formatExpDate(slotProps.data.credit_card_exp) }}
                    </template>
                  </Column>
                  <template #footer> <Button label="+ Use a New Card" severity="secondary" text @click="toggleUseNewCard" class="h-2rem use-new-card text-md" /> </template>
                </DataTable>
                <div
                  class="sm:col-12 md:col-12 lg:col-12"
                  v-if="this.displayIframe && !this.success"
                >
                  <iframe
                    v-resize="{
                      log: false,
                      minHeight: 175,
                    }"
                    :src="payment.iframeurl"
                    :key="iframeKey"
                    class="w-full"
                    id="iframe"
                    frameborder="0"
                  >
                  </iframe>
                </div>
                <div v-if="this.allowWaiveConvenienceFee"  class="
                      field-checkbox
                      sm:col-12
                      md:col-12
                      lg:col-12
                      align-items-start
                    "><Checkbox
                      id="overrideCCConvenienceFee"
                      v-model="waiveCCConvenienceFeeValue"
                      :binary="true"
                      @change="onWaiveCCFeeChange"
                    />
                    <label for="overrideCCConvenienceFee"
                      >Waive Convenience Fee</label
                    >
                  </div>
                <div v-if="this.getCCFee > 0 && this.CC && !this.waiveCCConvenienceFeeValue" class="justify-content-center text-center">A fee of {{ this.getCCFee }}% will be added to this Credit Card Payment.</div>
                
                <div
                  class="sm:col-12 md:col-12 lg:col-12 text-center"
                  v-if="
                    this.success && !this.displayIframe && !this.orderSuccess && this.selectedCC.credit_card_id === this.token
                  "
                >
                <div class="text-center">
                  <b>
                    <i class="pi pi-check-circle mr-2 text-green-500"></i>
                    <span class="text-green-700">Credit Card Validation Successful</span>
                  </b>
                </div>
                  <div class="mt-2">
                    <Checkbox inputId="saveCC" v-model="saveCC" :binary="true"></Checkbox>
                    <label for="saveCC" class="ml-2">Save this credit card for future use</label>
                  </div>
                  <div v-if="saveCC" class="flex justify-content-center save-cc">
                      <span class="flex mt-2 p-float-label">
                        <InputText id="ccName" v-model="ccName" :useGrouping="false" class="h-2rem" :style="{width: '270px'}" @change="this.ccList[ccList.length - 1].cardholder_name = ccName; this.selectedCC.cardholder_name = ccName"/>
                        <label for="ccName">Credit Card Name</label>
                      </span>
                      </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <div class="sm:col-12 md:col-12 lg:col-6">
          <Card>
            <template #content>
              <div class="grid align-items-start justify-content-center">
                <template v-if="this.displayOrderSubmit">
                  <div class="col-10">
                    <strong>Invoices</strong>
                    <div
                      v-for="inv in invoice.invoicesToPay"
                      v-bind:key="inv.arId"
                      class="justify-content-between m-t2 flex"
                    >
                      <span class="block">
                        {{ inv.arId }} {{ `(${this.formatDate(inv.dueDate)})` }}: </span
                      ><span class="block"
                        >{{
                          inv.balance.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })
                        }}
                        <span
                          class="pi pi-times pay-icon"
                          @click="this.removeInvoice(inv)"
                        ></span
                      ></span>
                    </div>
                    <Divider class="mt-1" />
                    <div
                      class="font-semibold"
                      style="
                        color: #d32f2f;
                        text-align: right;
                        padding-right: 20px;
                      "
                    >
                      TOTAL:
                      {{
                        this.total.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      }}
                    </div>
                  </div>
                  <div class="col-10">
                    <span class="p-float-label">
                    <InputText id="additionalReceiptEmail" v-model="additionalReceiptEmail" @change="validatePaymentForm"  />
                      <label for="additionalReceiptEmail">Additional Receipt Email</label>
                      </span>

                  </div>
                  <div class="col-10">
                    <Button
                      label="Submit Payment"
                      :icon="
                        this.payment.loading ? 'pi pi-spin pi-spinner' : ''
                      "
                      @click="submitPayment"
                      :disabled="
                        (!success &&
                          ((this.route == null || v$.route.$error) ||
                            this.account == null ||
                            this.type == null ||
                            this.agreement == false)) ||
                            this.total <= 0 ||
                        this.payment.loading
                      "
                    />
                    <div>
                      <small class="p-error" v-if="v$.$errors != null && v$.$errors?.length > 0 && (!this.CC && v$.$errors[0].$message === 'Please enter a routing number')">
                        {{ v$.$errors[0].$message }}
                      </small>
                    </div>
                    <div v-if="this.successBitArr[0] == 1">
                      <div class="col-12 text-pink-900 text-center">
                        <i class="pi pi-times-circle text-2xl"> </i>
                        <span class="ml-1">{{ this.error }}</span>
                      </div>
                    </div>
                  </div>                  
                </template>
                <div v-else-if="this.successBitArr[1] == 1">
                  <div class="col-12 text-green-900 text-center">
                    <i class="pi pi-check-circle text-2xl"></i>
                    <div>Payment Submitted Successfully</div>
                    <div>Record Id : {{ payment.recordId }}</div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </template>
  </Card>
</template>
<script>
import { defineComponent } from "vue";
import { mapState,mapGetters, mapActions } from "vuex";
import Card from "primevue/card";
import Button from "primevue/button";
import iframeResize from "iframe-resizer/js/iframeResizer";
import store from "@/store/index";
import Divider from "primevue/divider";
import ToggleButton from "primevue/togglebutton";
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";
import Tooltip from "primevue/tooltip";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import useVuelidate from "@vuelidate/core";
import Utils from "@/utility/utils";
import { numeric, minLength, maxLength, sameAs, minValue, helpers, required, email} from "@vuelidate/validators";
import CustomerService from "@/services/CustomerService";
const custService = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);
export default defineComponent({
  components: {
    Card,
    Button,
    Divider,
    ToggleButton,
    Checkbox,
    RadioButton,
    InputText,
    Column,
    DataTable
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  async created(){
    // populate the payment iframe url if not already populated
    if(this.payment.iframeurl == null || this.payment.iframeurl == ""){
      store.dispatch("payment/fetchPaymentInfo", {
      option: "IFRAMEURL ACHFLAG CCADRFLAG",
      Client: this.session.name,
    });
    if (this.getCompanyCodes == null || this.getCompanyCodes.length === 0) {
      await this.fetchControls({
        id: "COMPANY",
        procedure: "CO.CONTROL",
        filename: "CONTROL",
      }).then(() => {
        this.setSelectedCoCode(
          this.getCompanyCodes && this.getCompanyCodes.length > 0
            ? this.getCompanyCodes[0].code
            : ""
        );
      });
    } else {
      this.setSelectedCoCode(
        this.getCompanyCodes && this.getCompanyCodes.length > 0
          ? this.getCompanyCodes[0].code
          : ""
      );
    }
    await this.fetchControls({
      Client: "",
      id: "CC", 
      procedure: "CC.CONTROL",
      filename: "CONTROL",      
      });
      // update the CCFee value if getCCFee is defined and not null
      this.CCFee = this.getCCFee != null ? this.getCCFee : 0;
      this.ACHFee = this.getACHFee != null ? this.getACHFee : 0;
      this.waiveCCConvenienceFeeUsers = this.getWaiveConvenienceFeeUsers;
    await this.fetchControls({
      Client: "",
      id: "AR" + this.getSelectedCoCode,
      procedure: "AR.CONTROL",
      filename: "CONTROL",
      companyId: this.getSelectedCoCode,      
      });
      // update the CCFee value if getCCFee is defined and not null
      this.ACHFee = this.getACHFee != null ? this.getACHFee : 0;
    }
    if(this.index) {
      this.ccList = JSON.parse(JSON.stringify(this.getCust(this.index).credit_card_no_items || []));
      // Filter the items where credit_card_id is not null and not an empty string
      this.ccList = this.ccList.filter(item => item.credit_card_id && item.credit_card_id.trim());
      this.ach_accounts = this.getCust(this.index).bank_routing_items
    }
    else {
      //TODO: test
      custService.getCustomer(this.invoice.invoicesToPay[0].cust, store.getters["session/getClient"], "bank_token")
      .then((resp) =>{
        this.localCust = resp
        this.ccList = JSON.parse(JSON.stringify(resp.credit_card_no_items || []))
        this.ccList = this.ccList.filter(item => item.credit_card_id && item.credit_card_id.trim());
        this.ach_accounts = resp.bank_routing_items || []
      })
      
    }
    
  },  

  validations() {
    
    const baseRules = {
      additionalReceiptEmail: {
      email: helpers.withMessage("Please enter a valid email address",email)
    },
    total: {
        minValue: helpers.withMessage("Total amount cannot be a negative value", minValue(0))
      },
    }

    if(this.ACH){
      baseRules.route =  { 
        required: helpers.withMessage("Please enter a routing number", required),
        minLength: helpers.withMessage("Routing number should be at least 8 characters long", minLength(8)),
        maxLength: helpers.withMessage("Routing number max length is 9 characters long", maxLength(9)),
        numeric: helpers.withMessage("Routing number should be numeric", numeric)
      }
      baseRules.account =  {
        required: helpers.withMessage("Account number is required", required)
      }
      baseRules.agreement =  {
        sameAs: helpers.withMessage("Please accept the agreement", sameAs(true))
      }
    }
    return baseRules;  
  },


  directives: {
    tooltip: Tooltip,
    resize: {
      beforeMount: function (el, { value = {} }) {
        el.addEventListener("load", () => iframeResize(value, el));
      },
    },
  },
  data() {
    return {
      ach_accounts: [],
      localCust: null,
      ccName: "",
      selectedAccount: "",
      ccList: [],
      displayAddAccount: false,
      saveAccount: true,
      saveCC: true,
      selectedCC: "",
      success: false,
      expiry: "",
      token: "",
      tokenMessage: "",
      displayIframe: false,
      displayOrderSubmit: true,
      orderSuccess: false,
      error: "",
      successBitArr: [0, 0],
      ACH: false,
      CC: true,
      route: null,
      type: "Checking",
      account: null,
      agreement: false,
      types: ["Checking", "Savings"],
      waiveCCConvenienceFee: false,
      waiveACHConvenienceFee: false,
      waiveCCInteraction: false,
      waiveACHInteraction: false,
      ACHFee: 0,
      CCFee: 0,
      waiveCCConvenienceFeeUsers: [],
      additionalReceiptEmail: "",
      iframeKey: 0,
    };
  },
  props: {
    status: String,
    index: Number
  },
  mounted() {
    window.addEventListener("message", this.receiveToken);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveToken);
  },
  methods: {
    ...mapActions({
      fetchControls: "control/fetchControl",
      setSelectedCoCode: "customerInquiry/setSelectedCoCode",
      updateField: "customerInquiry/updateField",
      addNotification: "notification/add",
    }),
    handleError(title, message) {
      this.addNotification({
        title: title,
        message: message,
        type: "error",
      });
      this.success = false;
      this.reloadIframe();
    },
    checkErrorResponse(token) {
      if (token?.errorMessage && token?.errorMessage !== "") {
        this.handleError("Error", token.errorMessage);
        return;
      }
      if (token?.status === "501" && token?.statusMessage !== "") {
        this.handleError("Error", token.statusMessage);
        return;
      }
    },
    reloadIframe() {
      this.iframeSrc = ''; 
      this.iframeKey++; 
      setTimeout(() => {
        this.iframeSrc = this.getIframeUrl;
      }, 0);
    },  
    toggleUseNewCard() {
      this.displayIframe = !this.displayIframe;
      this.success = false;
      this.selectedCC = "";
      this.token = "";
    },
    toggleUseNewAccount() {
      this.displayAddAccount = !this.displayAddAccount; 
      this.selectedAccount = ''; 
      this.route = null; 
      this.account = null; 
      this.type = 'Checking'
    },
    formatExpDate(dateString) {
      if(dateString) {
        if (!dateString?.includes("/"))
          return dateString[0] + dateString[1] + "/" + dateString[2] + dateString[3];
        return dateString;
      }
      
    },
    formatCardNumber(card_id) {
      return "************" + card_id?.substring(12, 16);
    },
    async validatePaymentForm(){
      await this.v$.$validate()
    },
    onWaiveCCFeeChange() {
      this.CCFee = this.waiveCCConvenienceFee ? 0 : this.getCCFee;
          },
    onWaiveACHFeeChange() {
      this.ACHFee = this.waiveACHConvenienceFee ? 0 : this.getACHFee;
    },
    async setCCPaymentFormDefaults(){
      this.ACH = false,
      this.CC = true,
      this.route = null,
      this.account = null, 
      this.type = 'Checking',
      this.selectedAccount = '',
      this.displayAddAccount = false,
      this.agreement = false;    
      if(this.index) {
        this.ccList = JSON.parse(JSON.stringify(this.getCust(this.index).credit_card_no_items || []));
      } 
      else {
        this.ccList = JSON.parse(JSON.stringify(this.localCust.credit_card_no_items || []));
      }
      this.ccList = this.ccList.filter(item => item.credit_card_id && item.credit_card_id.trim());
      await this.v$.$validate();

    },
     async setACHPaymentFormDefaults(){
      this.selectedCC = '', 
      this.success = false,
      this.ACH = true,
      this.CC = false,

      await this.v$.$validate();
      // without this second call, the validation does not trigger,
      // or at least does not trigger for the validation rules that are dynamic based on a check of this.ACH.
      await this.v$.$validate();
    },
    formatDate(date) {
      return Utils.formatDate(date);
    },
    submitPayment() {
      if (this.CC) {
        let li = 1
        let invoices = []
        this.invoice.invoicesToPay.forEach((ar) => {
          invoices.push({li: li.toString(), arid: ar.arId, amount: parseFloat(ar.balance).toFixed(2).toString(), ar_app_amt: parseFloat(ar.balance).toFixed(2).toString() })
          li++
        })
        let creditcard_no_items = [{credit_card_id: this.selectedCC.credit_card_id, creditcard_exp: this.selectedCC.credit_card_exp, creditcard_amt: this.total.toString(), cardholder_name: this.selectedCC.cardholder_name}]
        let saveFlag = false

        if((this.index && this.getCust(this.index).credit_card_no_items?.length !== this.ccList.length && this.saveCC)
            || (this.localCust && this.localCust.credit_card_no_items?.length !== this.ccList.length && this.saveCC)) {
          saveFlag = true
        }
        store
          .dispatch("payment/postPayment", {
            meta:{save_payment: saveFlag},
            check_amount: this.total.toString(),
            cust: this.invoice.invoicesToPay[0].cust,
            li_items: invoices,
            payment_type: 'CC',
            creditcard_no_items: creditcard_no_items,
            waive_conv_fee: this.waiveCCConvenienceFeeValue, 
            data_source: 'ROVERWEB',
            receipt_email_address: this.additionalReceiptEmail,        })
          .then((response) => {
            if (
              response.error &&
              response.error.startsWith("Changes were made to the")
            ) {
              this.orderSuccess = false;
              this.error =
                response.error + " Page will now refresh for accurate data.";
              this.displayOrderSubmit = true;
              this.successBitArr[0] = 1;
              setTimeout(() => {
                store.dispatch("invoice/clearInvoicesToPay");
                store.dispatch("invoice/setInvoices", {
                  custId: this.custId,
                  dateRange: null,
                  status: this.status,
                  sortBy: "",
                  addInvoices: false,
                  Client: store.getters["session/getClient"],
                  id: null,
                });
                this.$emit('payment-submitted');
                this.successBitArr = [0, 0];
              }, 3000);
            } else if (response.error) {
              this.orderSuccess = false;
              this.error = response.error;
              this.displayOrderSubmit = true;
              this.successBitArr[0] = 1;
            } else {
              this.orderSuccess = true;
              this.displayOrderSubmit = false;
              this.successBitArr[1] = 1;
              if(saveFlag && this.index) {
              //update cust
              this.updateField({
              field: "CREDIT",
              data: this.ccList,
              index: this.index,
            });
            }
              this.ccName = "";
              this.selectedCC = "";
              setTimeout(() => {
                //refreshes the invoices to get accurate data that represents the newly paid invoices
                store.dispatch("invoice/setInvoices", {
                  custId: this.custId,
                  dateRange: null,
                  status: this.status,
                  sortBy: "",
                  addInvoices: false,
                  Client: store.getters["session/getClient"],
                  id: null,
                });
                this.successBitArr = [0, 0];
                store.dispatch("invoice/clearInvoicesToPay");
                this.$emit('payment-submitted');
              }, 4000);
            }
          });
      } else if (this.ACH) {
        let saveFlag = false
        //TODO: test
        if((this.ach_accounts.find(el => String(el.bank_account) === String(this.account)) === undefined && this.saveAccount)) {
          saveFlag = true
        }
        let tempACH = false;
        //TODO: test
        if((this.ach_accounts.find(el => el.bank_account === String(this.account)) === undefined && !this.saveAccount)) {
          tempACH = true
        }
        store
          .dispatch("payment/postACH", {
            meta:{save_payment: saveFlag, temp_ach: tempACH},
            amount: this.total,
            cust: this.invoice.invoicesToPay[0].cust,
            invoices: this.invoice.invoicesToPay,
            route: String(this.route),
            account: String(this.account),
            type: this.type,
            Client: this.session.client,
            waive_conv_fee : this.waiveACHConvenienceFeeValue,
            data_source: 'ROVERWEB',
            receipt_email_address: this.additionalReceiptEmail,
          })
          .then((response) => {
            if (
              response.error &&
              response.error.startsWith("Changes were made to the")
            ) {
              this.orderSuccess = false;
              this.error =
                response.error + " Page will now refresh for accurate data.";
              this.displayOrderSubmit = true;
              this.successBitArr[0] = 1;
              setTimeout(() => {
                store.dispatch("invoice/clearInvoicesToPay");
                store.dispatch("invoice/setInvoices", {
                  custId: this.custId,
                  dateRange: null,
                  status: this.status,
                  sortBy: "",
                  addInvoices: false,
                  Client: store.getters["session/getClient"],
                  id: null,
                });
                this.successBitArr = [0, 0];
                this.$emit('payment-submitted');
              }, 3000);
            } else if (response.error) {
              this.orderSuccess = false;
              this.error = response.error;
              this.displayOrderSubmit = true;
              this.successBitArr[0] = 1;
            } else {
              this.orderSuccess = true;
              this.displayOrderSubmit = false;
              this.successBitArr[1] = 1;
              if(saveFlag && this.index) {
              //update cust
                let newAchAccounts = this.getCust(this.index).bank_routing_items
                let masked_account = [...this.account].reduce((acc, x, i) => (i<this.account.length - 4) ? acc + '*' : acc + x, '')
                newAchAccounts.push({bank_token: masked_account, bank_account: this.account, bank_routing: this.route, account_type: this.type})
                this.updateField({
                field: "ACH",
                data: newAchAccounts,
                index: this.index,
              })
              }
              setTimeout(() => {
                //refreshes the invoices to get accurate data that represents the newly paid invoices
                store.dispatch("invoice/setInvoices", {
                  custId: this.custId,
                  dateRange: null,
                  status: this.status,
                  sortBy: "",
                  addInvoices: false,
                  Client: store.getters["session/getClient"],
                  id: null,
                });
                this.successBitArr = [0, 0];
                store.dispatch("invoice/clearInvoicesToPay");
                this.$emit('payment-submitted');
              }, 4000);
            }
          });
      }
    },
    receiveToken(event) {
      if (event.origin.includes("gateway.total-computing.com")) {
        var token = event.data;
        if (token.success) {
          this.success = true;
          this.token = token.message;
          this.expiry = token.expiry;
          this.displayIframe = !this.displayIframe;
          if(this.ccList !== undefined) {
            if(this.ccList.find(el => el.credit_card_id === token.message && el.credit_card_exp === this.expiry) === undefined) {
              this.ccList.push({credit_card_id: token.message, credit_card_exp: this.expiry})
            }
          }
          else {
            this.ccList = [{credit_card_id: token.message, credit_card_exp: this.expiry}]
          }
          this.selectedCC = {credit_card_id: this.token, credit_card_exp: this.expiry}
        } else {
          this.checkErrorResponse(token);
        }
      }
    },
    removeInvoice(inv) {
      store.dispatch(`invoice/removeInvoiceToPay`, inv);
    },
  },
  computed: {
    ...mapState(["payment", "customer", "invoice", "session"]),
    ...mapGetters({
      getACHFee: "control/getACHFee",
      getCCFee: "control/getCCFee",
      getWaiveConvenienceFeeUsers: "control/getWaiveConvenienceFeeUsers",
      getWaiveConvenienceFeeByDefault: "control/getWaiveConvenienceFeeByDefault",
      getUser: "session/getUser",
      getCust: "customerInquiry/getCustomer",
      getCompanyCodes: "control/getCompanyCodes",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
    }),
    allowWaiveConvenienceFee() {
    if(this.getWaiveConvenienceFeeUsers.includes(this.getUser.user_id)){
        return true;
      }
      else{
        return false;
      }
    },
    defaultWaiveConvenienceFee() {
      return this.getWaiveConvenienceFeeByDefault && this.allowWaiveConvenienceFee && !this.defaultWaiveOverridden ? true : false;
    },
    waiveACHConvenienceFeeValue: {
      get() {
        return this.waiveACHInteraction ? this.waiveACHConvenienceFee :this.defaultWaiveConvenienceFee ;
      },
      set(value){
        this.waiveACHConvenienceFee = value;   
        this.waiveACHInteraction = true;    
      }
      
    },
    waiveCCConvenienceFeeValue: {
      get() {
        return this.waiveCCInteraction ? this.waiveCCConvenienceFee :this.defaultWaiveConvenienceFee ;
      },
      set(value){
        this.waiveCCConvenienceFee = value;
        this.waiveCCInteraction = true;    
      }
    },
    total() {
      let sum = 0;
      this.invoice.invoicesToPay.forEach((inv) => {
        sum += +inv.balance;
      });
      return Math.round(sum * 100) / 100;
    },
  },
});
</script>
