
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Card from "primevue/card";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Button from "primevue/button";

import ReceivablesTable from "./ReceivablesTable.vue";
import AddDateRangeDialog from "../AddDateRangeDialog.vue";
import PaymentCard from "@/components/PaymentCard.vue";

export default defineComponent({
  components: {
    Card,
    TabPanel,
    TabView,
    ReceivablesTable,
    AddDateRangeDialog,
    Button,
    PaymentCard,
  },

  data() {
    return {
      activeTab: 0,
      dateRanges: [] as any[],
      extraDateRanges: [] as any[],
      showAddTabDialog: false,
      newTab: {
        label: '',
        range: '',
      }
    };
  },
  computed:{
    ...mapGetters({
      getActiveTab: 'accountingReceivables/getActiveTab',
      getDefaultDateRanges: 'accountingReceivables/getDefaultDateRanges',
      getCustomDateRanges: 'accountingReceivables/getCustomDateRanges',
      invoicesToPay: 'invoice/getInvoicesToPay'
    })
  },

  created() {
    this.activeTab = this.getActiveTab;
    this.dateRanges = this.getDefaultDateRanges;
    this.extraDateRanges = this.getCustomDateRanges;
  },

  methods: {
    ...mapActions({
      setActiveTab: 'accountingReceivables/setActiveTab',
      addCustomDateRange: 'accountingReceivables/addCustomDateRange',
      removeCustomDateRange: 'accountingReceivables/removeCustomDateRange',
      updateCustomDateRange: 'accountingReceivables/updateCustomDateRange',
    }),
    handleTabChange(e: any) {
      this.activeTab = e.index;
      this.setActiveTab(e.index);
    },
    showAddTab() {
      this.showAddTabDialog = true;
    },
    closeAddTab() {
      this.showAddTabDialog = false;
      this.newTab = {
        label: '',
        range: '',
      }
    },
    addTab(data: any) {
      const index = this.getCustomDateRanges.findIndex((range: any) => range.label.toLowerCase() === data.label.toLowerCase());
      const defaultLength = this.getDefaultDateRanges.length;
      if (index > -1) {
        this.updateCustomDateRange(data);
        this.activeTab = defaultLength + index;
        this.setActiveTab(defaultLength + index);

      } else {
        this.addCustomDateRange(data);
        this.activeTab = defaultLength + (this.getCustomDateRanges.length -1);
        this.setActiveTab(defaultLength + (this.getCustomDateRanges.length -1));

      }
      this.showAddTabDialog = false;
    },
    handleCloseCustomTab(label: string, index: number, event: any) {
      event.stopPropagation();
      this.removeCustomDateRange(label);
      const defaultLength = this.getDefaultDateRanges.length;
      
      if (this.getActiveTab > 3 && this.getCustomDateRanges.length === 0) {
        this.activeTab = 3;
        this.setActiveTab(3);
      }

      if (this.getActiveTab > 3 && this.getCustomDateRanges.length > 0) {
        this.activeTab = defaultLength + (this.getCustomDateRanges.length -1);
        this.setActiveTab(defaultLength + (this.getCustomDateRanges.length -1));
      }
    }
  },

  watch: {
    getCustomDateRanges: {
      handler() {
        this.extraDateRanges = this.getCustomDateRanges;
      },
      deep: true,
    },
    getActiveTab(idx: number) {
      this.activeTab = idx;
    }
  },
});
